<template>
  <page-header-wrapper :title='this.$route.meta.title'>
    <a-row :gutter='24'>

      <a-col :sm='12' :md='6' :xl='15' :style="{ marginBottom: '24px' }">
        <a-card>
          <div class="card_content">
            <a-row>
              <a-col :span="3"><span class="card_title">首次订单数</span></a-col>
              <a-col :span="3"><span class="card_title">首次订单金额</span></a-col>
              <a-col :span="3"><span class="card_title">复购订单数</span></a-col>
              <a-col :span="3"><span class="card_title">复购订单金额</span></a-col>
              <a-col :span="3"><span class="card_title">上月份额总数</span></a-col>
              <a-col :span="3"><span class="card_title">份额总数</span></a-col>
            </a-row>
          </div>
          <div class="card_content">
            <a-row>
              <a-col :span="3"><span class="color-red">{{ dashboard.firstCount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.firstAmount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.repeatCount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.repeatAmount }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.lastMonthTotalShare }}</span></a-col>
              <a-col :span="3"><span class="color-red">{{ dashboard.totalShare }}</span></a-col>
            </a-row>
          </div>
        </a-card>
      </a-col>
    </a-row>

    <a-form-model ref='form' :model='form' layout='inline'>
      <a-form-model-item prop='brandCode'>
        <a-select v-model='form.brandCode' @change="changeBrand" style="width: 200px">
          <a-select-option value="">
            全部品牌公司
          </a-select-option>
          <a-select-option :value="item.brandCode" v-for="(item, index) in brandList" :key="index">
            {{item.brandName}}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item prop='goalStatus'>
        <a-select v-model='form.goalStatus' style="width: 200px">
          <a-select-option value="2">
            进行中
          </a-select-option>
          <a-select-option value="3">
            已完成
          </a-select-option>
        </a-select>
      </a-form-model-item>


      <a-form-model-item>
        <a-button type='primary' @click='handleSubmit'>
          查询
        </a-button>
      </a-form-model-item>
      <a-form-model-item>
        <a-button @click='handleReset'>
          重置
        </a-button>
      </a-form-model-item>
    </a-form-model>

    <div>
      <div class='table-operator'>
        <a-button type='primary' icon='reload' @click='handleSubmit'>刷新</a-button>
      </div>

      <s-table ref='table' rowKey='id' :columns='columns' :scroll='{ x: "100%"}' :data='loadData'>
        <span slot='status' slot-scope='text, record'>
          <template>
            {{getStatusStr(text)}}
          </template>
        </span>

        <span slot='currentValue' slot-scope='text, record'>
          <template>
            <span class="color-red">{{text}}</span>
          </template>
        </span>

        <span slot='progress' slot-scope='text, record'>
          <template>
            {{ getProgress(record) }}
          </template>
        </span>

        <span slot='action' slot-scope='text, record'>
          <template>
            <span>
              <a-divider type='vertical' />
              <a @click='openDetail(record)'>详情</a>
            </span>
            <span>
              <a-divider type='vertical' />
              <a v-if="record.status=='2'" @click='beforeOver(record)'>提前结束</a>
            </span>
          </template>
        </span>
      </s-table>
    </div>
  </page-header-wrapper>
</template>

<script>
import { STable, Ellipsis } from '@/components'
import {memberHeardData, removeOperator} from '@/api/operator'
import moment from "moment";
import {queryShareHeard, queryShareLise, toBeforeOver} from "@/api/share";
import {queryBrand} from "@/api/cash";

const columns = [
  {
    width: 120,
    title: '品牌公司',
    dataIndex: 'brandName',
    scopedSlots: { customRender: 'brandName' }
  },
  {
    width: 80,
    title: '目标业绩',
    dataIndex: 'value',
    scopedSlots: { customRender: 'value' }
  },
  {
    width: 100,
    title: '当前业绩',
    dataIndex: 'currentValue',
    scopedSlots: { customRender: 'currentValue' }
  },
  {
    width: 100,
    title: '进度比',
    dataIndex: 'progress',
    scopedSlots: { customRender: 'progress' }
  },
  {
    width: 80,
    title: '目标名称',
    dataIndex: 'name',
    scopedSlots: { customRender: 'name' }
  },

  {
    width: 80,
    title: '份额值',
    dataIndex: 'currentShare',
    scopedSlots: { customRender: 'currentShare' }
  },
  {
    width: 80,
    title: '份额数',
    dataIndex: 'totalShare',
    scopedSlots: { customRender: 'totalShare' }
  },
  {
    width: 80,
    title: '首次',
    dataIndex: 'firstCount',
    scopedSlots: { customRender: 'firstCount' }
  },
  {
    width: 80,
    title: '复购',
    dataIndex: 'repeatCount',
    scopedSlots: { customRender: 'repeatCount' }
  },

  {
    width: 80,
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    width: 200,
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

const formatType = 'YYYY-MM-DD';
const baseStart = moment().startOf('month').format(formatType);
const baseEnd = moment().format(formatType);

export default {
  name: 'OperatorList',
  components: {
    STable, Ellipsis
  },
  data() {
    return {
      formatType,
      dashboard: {}, //表头用户数统计
      dateArr: [baseStart, baseEnd],
      form: {
        brandCode: "",
        goalStatus:"2",
      },
      brandList:[],//品牌公司列表
      // 表头
      columns,
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        let brandCode = this.form.brandCode;
        let goalStatus = this.form.goalStatus;
        // if(!brandCode || brandCode==""){
        //   return
        // }
        let params= Object.assign(parameter, this.queryParam,{brandCode:brandCode,goalStatus:goalStatus});
        return queryShareLise(params)
          .then(datum => {
            // debugger;
            return datum
          })
      }
    }
  },
  created() {
    this.queryBrand();
      // this.getHeaderData();
  },
  methods: {
    getProgress(record){
      let rate = 0;
      if(record.currentShare && record.currentShare>100){
        let tempShareValue = record.currentShare-100;
        rate = Math.round(tempShareValue*100)/100;
      }
      return rate+"%";
    },
    getStatusStr(text) {
      if(text =='1'){
        return '未开始';
      } else if(text =='2'){
        return '进行中';
      } else if(text =='3'){
        return '已完成';
      }
    },
    getHeaderData(){
      let brandCode = this.form.brandCode;
      let goalStatus = this.form.goalStatus;
      let params= Object.assign({brandCode:brandCode,goalStatus:goalStatus,queryType:'0'});
      queryShareHeard(params).then(result =>{
        this.dashboard = result;
        this.loading = false;
      });
    },
    openDetail(record){
      let params = {
          brandCode:record.brandCode,
          id: record.id,
          status:record.status,
      }
      // debugger
      this.$router.push({
        path: '/points/share/edit',
        query:params
      });
    },
    //提前结束
    beforeOver(record){
      let params = Object.assign({id:record.id});
      toBeforeOver(params).then(result=>{
        this.$message.success('操作成功')
        this.$refs.table.refresh(true)
      })
    },
    handleSubmit() {
      this.$refs.table.refresh(true)
      this.loading=true;
      this.getHeaderData();
    },
    //获取品牌公司列表
    queryBrand(){
      let month  = moment().startOf('date').format('YYYYMM');
      let params = Object.assign({pageNum:1,pageSize:100,month:month});
      queryBrand(params).then(result=>{
        // debugger;
        let list = result.records;
        //顺便设置选择到的值
        this.brandList = list;

        if(list && list.length>0){
          // this.form2 = {brandCode:list[0].brandCode};
          this.handleSubmit();
        }else{
          this.$message.error("缺少品牌公司，数据无法展示！");
        }
        // this.getHeaderData();//加载完成才能开始搜索顶部数据
      })
    },
    changeBrand(){
      this.handleSubmit();
    },
    getBrandName(record){
      let code = record.brandCode;
      let name = '未知';
      // debugger
      for(let item of this.brandList){
        if(item.brandCode==code){
          name =  item.brandName;
          break;
        }
      }
      return name;
    },
    handleReset() {
      this.$refs.form.resetFields()
      this.$refs.table.refresh(true)
    },

  }
}
</script>
