import request from '@/utils/request'

export function queryShareLise(parameter) {
  return request({
    url: '/integral/performance/goal/config/share/page',
    method: 'post',
    data: parameter
  })
}

export function queryShareHeard(parameter) {
  return request({
    url: '/integral/performance/goal/config/share/statistics',
    method: 'post',
    data: parameter
  })
}

export function queryShareDetailList(parameter) {
  return request({
    url: '/integral/performance/goal/config/share/order/page',
    method: 'post',
    data: parameter
  })
}

export function toBeforeOver(parameter) {
  return request({
    url: '/integral/performance/goal/config/stop/'+parameter.id,
    method: 'post',
    data: parameter
  })
}


